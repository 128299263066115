import axios from 'axios'
import { navigate } from 'gatsby'
import LinkWrap from 'components/LinkWrap'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import { strapi } from 'utils/functions'
var hasher = require('wordpress-hash-node')

// TODO: login returns to previous page

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postedEmail: null,
      error: null,
      loading: false,
    }
  }

  render() {
    const tr = this.props.tr
    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      // check if the email exists
      let urlCheckEmail = strapi.url
      urlCheckEmail += `/users?email=${values.email}`

      axios({
        method: 'get',
        url: urlCheckEmail,
        headers: {
          Authorization: `Bearer ${strapi.token}`,
        },
      }).then((response) => {
        if (response.data.length < 1) {
          // no user matching email
          this.setState({ error: '' })
          setTimeout(() => {
            this.setState({
              postedEmail: null,
              error: 'no email',
              loading: false,
            })
          }, 500)
        } else {
          const gotUser = response.data[0]
          const getPasswordEncrypted = gotUser.password2
          const passwordMatch = hasher.CheckPassword(
            values.password,
            getPasswordEncrypted,
          )

          if (!passwordMatch) {
            this.setState({ error: '' })
            setTimeout(() => {
              this.setState({
                postedEmail: values.email,
                error: 'no password',
                loading: false,
              })
            }, 500)
          } else {
            // log in
            this.setState({ postedEmail: null, error: null, loading: false })
            sessionStorage.setItem('loginUser', JSON.stringify(gotUser))
            navigate('/my-account/welcome')
          }
        }
      })
    }

    const loadingClass = this.state.loading ? 'loading' : ''
    return (
      <div className="login-columns">
        <div className="col description">
          <h6 className="tiny-caps">{tr('MY_ACCOUNT_REQUEST_ACCESS')}</h6>
          <p>{tr('MY_ACCOUNT_REGISTER_TO_DOWNLOAD')}</p>
          <p className="form-buttons">
            <LinkWrap to="/my-account/registration" className="button">
              {tr('MY_ACCOUNT_REGISTER_AS_TRADE_PRESS')}
            </LinkWrap>
          </p>
        </div>
        <div className="col">
          <h6 className="tiny-caps">{tr('MY_ACCOUNT_TRADE_PRESS_LOGIN')}</h6>

          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className="woocommerce-form-login" onSubmit={handleSubmit}>
                <div className="woocommerce-form-login">
                  <p className="form-row form-row-wide">
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      placeholder={tr('FORM_ITEM_EMAIL')}
                      required
                    />
                  </p>
                  <p className="form-row form-row-wide">
                    <Field
                      name="password"
                      component="input"
                      type="password"
                      placeholder={tr('FORM_ITEM_PASSWORD')}
                      required
                    />
                  </p>

                  <LinkWrap
                    className="lost_password"
                    to="/my-account/lost-password"
                  >
                    {tr('FORM_ITEM_LOST_PASSWORD')}
                  </LinkWrap>

                  <div className={`submit-wrap ${loadingClass}`}>
                    <span className="loader">
                      <ReactSVG src="/images/loader.svg" />
                    </span>
                    <input
                      className="button primary"
                      type="submit"
                      value={tr('FORM_ITEM_LOGIN')}
                      disabled={this.state.loading}
                    />
                  </div>
                </div>
              </form>
            )}
          />

          <br />
          <ul className="error-holder">
            <li
              className={`placeholder ${
                this.state.error === 'no email' ? 'show' : ''
              }`}
            >
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_LOGIN_ERROR_NO_EMAIL')}
            </li>
            <li className={this.state.error === 'no email' ? 'show' : ''}>
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_LOGIN_ERROR_NO_EMAIL')}
            </li>

            <li className={this.state.error === 'no password' ? 'show' : ''}>
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_LOGIN_ERROR_PASSWORD_WRONG') +
                this.state.postedEmail +
                tr('FORM_LOGIN_ERROR_PASSWORD_WRONG_2')}
              <LinkWrap to="/my-account/lost-password">
                {tr('FORM_ITEM_LOST_PASSWORD')}
              </LinkWrap>
            </li>
            <li
              className={
                'placeholder ' +
                (this.state.error === 'no password' ? 'show' : '')
              }
            >
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_LOGIN_ERROR_PASSWORD_WRONG') +
                this.state.postedEmail +
                tr('FORM_LOGIN_ERROR_PASSWORD_WRONG_2')}
              <LinkWrap to="/my-account/lost-password">
                {tr('FORM_ITEM_LOST_PASSWORD')}
              </LinkWrap>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default LoginForm
