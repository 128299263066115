import React, { Component } from 'react'
import LoginForm from 'components/forms/login'
import Layout from 'components/layout'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby'

class MyAccount extends Component {
  UNSAFE_componentWillMount() {
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.getItem('loginUser')) {
        navigate('/my-account/logged-in')
      }
    }
  }

  render() {
    const data = this.props.data
    const locale = data.site.siteMetadata.locale
    const translations = data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const page = data.thisPage

    return (
      <Layout>
        {typeof sessionStorage !== 'undefined' &&
          !sessionStorage.getItem('loginUser') && (
            <div id="content" className="page-template-page-account">
              <Title title={page.title} />
              <section className="page-content wrap">
                <div className="woocommerce">
                  <div className="section">
                    <div className="small-caps section-title">
                      {tr('MY_ACCOUNT_TRADE_PRESS_ACCOUNTS')}
                    </div>
                    <LoginForm tr={tr} />
                  </div>
                </div>
              </section>
            </div>
          )}
      </Layout>
    )
  }
}

export default MyAccount

export const myAccountQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "my-account" }) {
      title
      slug
    }
  }
`
